import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { images, keys } from '../pages/config';
import { useContext } from 'react';
import { Context } from '../context';

function MydModalWithGrid(props) {
  const { show, postLists, selectedKey, onHide } = props;
  const { t } = useContext(Context)
  if (!selectedKey) {
    return null;
  }

  return (
    <>
      {postLists.map((post, i) => {
        const skBlerje = post[`${selectedKey}Blej`];
        const skShitje = post[`${selectedKey}Shes`];

        return (
          <Modal key={i} show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {selectedKey.toUpperCase()}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
              <Container>
                <div className='row ratesRow bckgr'>
                  <div className='col-4'>
                    <p className=''>{t('VALUTA')}</p>
                  </div>
                  <div className='col-4'>
                    <p className=''>{t('BLEJMË')}</p>
                  </div>
                  <div className='col-4'>
                    <p className=''>{t('SHESIM')}</p>
                  </div>
                </div>
                {keys.filter(k => k !== selectedKey).map(k => {
                  const blejKey = `${k}Blej`;
                  const shesKey = `${k}Shes`;
                  return (
                    <div key={`${k}-${i}`} className='row ratesRow'>
                      <div className='col-4'>
                        <img className='curImg' src={images[k]}></img>
                        <p className='curName'>{k.toUpperCase()}</p>
                      </div>
                      <div className='col-4'>
                        <p>{Number(skBlerje / post[shesKey]).toFixed(3)}</p>
                      </div>
                      <div className='col-4'>
                        <p>{Number(skShitje / post[blejKey]).toFixed(3)}</p>
                      </div>
                    </div>
                  )
                })}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide}>{t('Mbyll')}</Button>
            </Modal.Footer>
          </Modal>
        );
      })}
    </>

  );
}

export default MydModalWithGrid;

