import React, { useContext, useEffect, useState } from 'react';
import { getDocs, collection, query, orderBy, limit, Timestamp } from "firebase/firestore";
import { auth, db } from "../firebase-config";
import { getDatabase } from "firebase/database";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../index.css";
import "../App.css";
import '../styles/Home.css'
import { keys, images } from './config';
import MydModalWithGrid from '../currencyModals/Modal'
import { Context } from '../context';

const database = getDatabase();

function Home({ isAuth }) {
  const [postLists, setPostList] = useState([]);
  const postsCollectionRef = collection(db, "rates");

  const [selectedKey, setSelectedKey] = useState();

  const [modalShow, setModalShow] = useState(false);
  const { t, language, changeLanguage } = useContext(Context)


  useEffect(() => {
    const getPosts = async () => {

      const q = query(postsCollectionRef, orderBy("timestamp", "desc"), limit(1));

      const data = await getDocs(q);

      setPostList((data.docs || []).map((doc) => ({ ...doc.data(), id: doc.id })));

    };

    getPosts();
  }, []);

  return (
    <div className='container all'>
      <MydModalWithGrid
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setSelectedKey(undefined);
        }}
        selectedKey={selectedKey}
        postLists={postLists}
      />
      <div className='row'>
        <div className='col-md-8 col-sm-12'>
          {postLists.map((post, i) => {
            return (
              <div key={`key-${i}`} className='container ratesTable'>
                <div className='row ratesRow'>
                  <div className='col-4'>
                    <p className='valuta'>{t('VALUTA')}</p>
                  </div>
                  <div className='col-4'>
                    <p className='blejme'>{t('BLEJMË')}</p>
                  </div>
                  <div className='col-4'>
                    <p className='shesim'>{t('SHESIM')}</p>
                  </div>
                </div>
                {keys.map(k => {
                  const blejKey = `${k}Blej`;

                  const shesKey = `${k}Shes`;
                  return (
                    <div
                      key={`${k}-${i}`}
                      onClick={() => {
                        setModalShow(true);
                        setSelectedKey(k);
                      }}
                      className='row ratesRow'
                    >
                      <div className='col-4'>
                        <img className='curImg' src={images[k]} alt=""></img>
                        <p className='curName'>{k.toUpperCase()}</p>
                      </div>
                      <div className='col-4'>
                        <p className='curValue'>{post[blejKey]}</p>
                      </div>
                      <div className='col-4'>
                        <p className='curValue'>{post[shesKey]}</p>
                      </div>
                    </div>
                  )

                })

                }
                <p className='perditesuar'>{t('Përditësuar')} {post.dt} {t('ora')}: {post.ora}:{post.min}</p>
              </div>
            );
          })}
        </div>
        <div className='side col-md-4 col-sm-12'>
          <a href='/convert'><button className='homeSideBtn'>{t('Konverto')}</button></a>
          <a href='/news'><button className='homeSideBtn'>{t('Lajme')}</button></a>
        </div>
      </div >
    </div >
  )
}

export default Home;