import React, { useState, useEffect } from "react";
import { getDatabase, ref, set } from "firebase/database";
import { db, auth } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import { storage } from '../firebase-storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import uniqid from 'uniqid';
import '../index.css'
import '../styles/VendosKursin.css'
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

function CreateNews({ isAuth }) {
  const [from, setFrom] = useState("");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [imazh, setImazh] = useState(null);

  const postsCollectionRef = collection(db, "news");
  let navigate = useNavigate();

  const createPost = async () => {
    const uploadTask = storage.ref(`images/${imazh.name}`).put(imazh);
    uploadTask.on(
      `state_changes`,
      snapshot => { },
      error => { console.log(error) },
      async () => {
        const setUrl = await storage.ref('images').child(imazh.name).getDownloadURL().then(async (url) => {
          await addDoc(postsCollectionRef, {
            from: from,
            title: title,
            link: link,
            imazh: url,
            timestamp: serverTimestamp()
            // author: { name: auth.currentUser.displayName, id: auth.currentUser.uid }
          });

          navigate("/");

        })
      }
    )
  };

  useEffect(() => {
    if (!isAuth) {
      navigate("/loginalbkredit-admin");
    }
  }, []);

  return (
    <>
      <div className='' style={{ 'marginTop': '150px' }}>
        <div className='container lajmSection'>

          <div className=''>
            <label>Nga</label>
            <input
              onChange={(event) => {
                setFrom(event.target.value);
              }} name='nga' />
          </div>

          <div className=''>
            <label>Titulli</label>
            <input
              onChange={(event) => {
                setTitle(event.target.value);
              }} name='title' />
          </div>

          <div className=''>
            <label>Link</label>
            <input
              onChange={(event) => {
                setLink(event.target.value);
              }} name='url' />
          </div>

          <div className="">
            <label htmlFor="imazh" className="">Imazh</label>
            <input onChange={(event) => {
              if (event.target.files[0]) {
                const image = new File([event.target.files[0]], `${new Date().toISOString()}-${event.target.files[0].name}`);
                setImazh(image)
              }
            }} id="imazh" type="file" name="imazh" className="form-control" required />
            <span className="validity"></span>
          </div>

          <button className="btn btn-primary m-4" onClick={createPost}>Submit</button>

        </div>

      </div>
    </>
  )
}

export default CreateNews