
// per te pekthyer perdor kete funksionin t() ahaaa
// context perdoret, eshte state global
// use context e perdor ne cdo komponent brenda providerit
// po deshe te shtosh key te tjera, duhet ti shtosh tek en.js dhe sq.js
// dhe ruhet ne localStorage qe te ruhet dhe kur mbyll sessionin ne google ok

import "./App.css";
import "./index.css";
import './styles/Navbar.css'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "./firebase-config";
import { Navbar, Container, Nav } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from './img/logo.jpg'
import VendosKurs from "./pages/CreateCur";
import About from './pages/About';
import Convert from './pages/Convert'
import CreateNews from "./pages/CreateNews";
import News from "./pages/News";
import { useContext } from "react";
import { Context } from "./context";
import moneyImg from "./img/bck.jpg"

function App() {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));

  const { t, language, changeLanguage } = useContext(Context)

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      window.location.pathname = "/";
    });
  };

  return (
    <Router>
      <Navbar expand="md" className='navbar fixed-top'>
        <div className="moneyImg">
          <img src={moneyImg}></img>
        </div>
        <Container>

          <a href='/'>
            <img src={Logo} className='logo'></img>
            <p className="slogan"> {t('...që nga 1993')}</p>
          </a>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto mx-5">
              <Nav.Link className='nav-item' href="/"> {t('Kreu')}</Nav.Link>
              <Nav.Link className='nav-item' href="/convert">{t('Konverto')}</Nav.Link>
              <Nav.Link className='nav-item' href="/about">{t('Rreth nesh')}</Nav.Link>
              <Nav.Link href="#">{!isAuth ? (
                <Link className="nav-item" to="#"> </Link>
              ) : (
                <>
                  <div className="">
                    <Link className="nav-item" to="/vendoskurs">{t('Vendos Kurset')}</Link>
                    <Link className="nav-item lajm" to="/vendoslajm"> {t('Vendos Lajmet')}</Link>
                    <button className="logOutBtn" onClick={signUserOut}> Log Out </button>
                  </div>
                </>
              )}</Nav.Link>
              <select className="lang" value={language} onChange={(e) => changeLanguage(e.target.value)}>
                <option className="langOption" value="sq">AL</option>
                <option className="langOption" value="en">EN</option>
              </select>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Routes>
        <Route path="/" element={<Home isAuth={isAuth} />} />
        <Route path="/vendoslajm" element={<CreateNews isAuth={isAuth} />} />
        <Route path="/news" element={<News isAuth={isAuth} />} />
        <Route path="/convert" element={<Convert isAuth={isAuth} />} />
        <Route path="/about" element={<About isAuth={isAuth} />} />
        <Route path="/vendoskurs" element={<VendosKurs isAuth={isAuth} />} />
        <Route path="/loginalbkredit-admin" element={<Login setIsAuth={setIsAuth} />} />
      </Routes>
    </Router >
  );
}

export default App;

// lol