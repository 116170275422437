import EUR from '../img/euro.jpg'
import USD from '../img/usd.jpg'
import CHF from '../img/chf.jpg'
import GBP from '../img/gbp.jpg'
import CAD from '../img/cad.jpg'
import AUD from '../img/aud.jpg'
import JPY from '../img/jpy.jpg'
import SEK from '../img/sek.jpg'
import NOK from '../img/nok.jpg'
import DKK from '../img/dkk.jpg'
import TRY from '../img/try.jpg'
import AED from '../img/aed.jpg'
import MKD from '../img/mkd.jpg'
import HUF from '../img/huf.jpg'
import BGN from '../img/bgn.jpg'
import RSD from '../img/rsd.jpg'
import CZK from '../img/czk.jpg'
import RON from '../img/ron.jpg'
import PLN from '../img/pln.jpg'
import BAM from '../img/bam.jpg'

export const keys = [
  'usd',
  'eur',
  'chf',
  'gbp',
  'cad',
  'aud',
  'jpy',
  'sek',
  'nok',
  'dkk',
  'try',
  'aed',
  'mkd',
  'huf',
  'bgn',
  'rsd',
  'czk',
  'ron',
  'pln',
  'bam'
];

export const images = {
  'usd': USD,
  'eur': EUR,
  'chf': CHF,
  'gbp': GBP,
  'cad': CAD,
  'aud': AUD,
  'jpy': JPY,
  'sek': SEK,
  'nok': NOK,
  'dkk': DKK,
  'try': TRY,
  'aed': AED,
  'mkd': MKD,
  'huf': HUF,
  'bgn': BGN,
  'rsd': RSD,
  'czk': CZK,
  'ron': RON,
  'pln': PLN,
  'bam': BAM
}