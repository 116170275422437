import { createContext, useCallback, useState } from "react";
import en from "./en";
import sq from "./sq";

const locales = { en, sq };

export const Context = createContext({
  language: 'sq',
  changeLanguage: () => { },
  t: () => { },
});

const getLanguage = () => {
  const localLng = localStorage.getItem('lng');

  if (localLng) {
    if (localLng === 'sq' || localLng === 'en') {
      return localLng;
    }

    localStorage.setItem('lng', 'sq');
    return 'sq';
  }

  return 'sq';

}

export const Provider = ({ children }) => {
  const [language, setLangage] = useState(getLanguage())

  const changeLanguage = useCallback((lng) => {
    setLangage(lng);
    localStorage.setItem('lng', lng);
  }, []);

  const t = useCallback((key = '') => {
    const localLng = locales[language];
    if (localLng) {
      return localLng[key] || key;
    }
    return key;
  })


  return (
    <Context.Provider value={{ language, changeLanguage, t }}>
      {children}
    </Context.Provider>
  )
}

