import React from 'react'
import 'react-bootstrap'
import '../styles/About.css'
import IMG1 from '../img/albImg1.jpg'
import IMG2 from '../img/albImg2.jpg'
import IMG3 from '../img/albImg3.jpg'
import IMG4 from '../img/albImg4.jpg'
import Carousel from 'react-bootstrap/Carousel';
import { useContext } from 'react';
import { Context } from '../context';
import WP from '../img/whatsapp.jpg'
import SIG from '../img/signal.jpg'
import PHONE from '../img/phone.jpg'
import YAHOO from '../img/yahoo.jpg'

function About() {
  const { t } = useContext(Context)

  return (
    <>
      <div className='container' style={{ 'marginTop': '200px' }}>

        <p className='title'>{t('Kush jemi ne?')}</p>
        <p className='text'><b>“ALB-KREDIT” SHPK</b> {t('është krenare të jetë e para shoqëri shqiptare për Këmbime Valutore prej vitit 1993. Ne jemi të specalizuar në Këmbime Valutore dhe në Transferta  Parash. Ne ofrojmë kurset e këmbimit më të mira per individet dhe bizneset dhe nuk aplikojmë komisione shtesë për këmbimin, si në rast kur këmbimi bëhet me para në dorë, por as në rastin kur këmbimi bëhet nëpërmjet llogarive bankare. Zyrat tona gjenden në qendrën e Tiranës dhe  të qytetit të Peshkopisë.')}
        </p>

        <div className='row'>
          <div className='row col-md-6 col-sm-12'>
            <p className='title'>{t('Ku mund të na gjeni?')}</p>
            <p className='textVend'>{t('Në Tiranë:')}</p>
            <p className='text'>{t('Rruga: “Ded Gjon Luli”, 1/14, Tiranë, Shqipëri')}</p>
            <p className='text'>{t('E Hënë - E Shtunë')} | 08:00 - 20:00</p>
            <p className='text'>{t('E Dielë')} | 08:00 - 13:00</p>
            <iframe style={{ 'marginBottom': '25px' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25517.0266631634!2d19.814323407222982!3d41.327323404717035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350310fa023a46b%3A0x7604daa92ab9f167!2sALB-KREDIT%20Shpk!5e0!3m2!1sen!2s!4v1663515362778!5m2!1sen!2s"
              width="400" height="350" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

            <p className='textVend'>{t('Në Peshkopi:')}</p>
            <p className='text'>{t('Bulevardi “Nazmi Rushiti”, Peshkopi, Shqipëri')}</p>
            <p className='text'>{t('E Hënë - E Shtunë')} | 08:00 - 15:00</p>
          </div>

          <Carousel fade className='slider row col-md-6 col-sm-12'>
            <Carousel.Item>
              <img
                className="d-block w-100 sliderImg"
                src={IMG1}
                alt=""
              />
              <Carousel.Caption>
                <h3>Tiranë</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 sliderImg"
                src={IMG2}
                alt=""
              />

              <Carousel.Caption>
                <h3>Tiranë</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 sliderImg"
                src={IMG3}
                alt=""
              />

              <Carousel.Caption>
                <h3>Tiranë</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 sliderImg"
                src={IMG4}
                alt=""
              />

              <Carousel.Caption>
                <h3>Peshkopi</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className='contactBox'>
          <div>
            <p className='title'> {t('Kontakt')} </p>
            <p className='textVend'>{t('Në Tiranë:')}</p>
            <div className='contact'>
              <img src={PHONE} className='contactMedia'></img>
              <p className='text nr'>Tel: +355 42 228 929</p>
            </div>
            <div className='contact'>
              <img src={WP} className='contactMedia'></img>
              <p className='text nr'>Msg: +355 69 20 95 802</p>
            </div>
            <div className='contact'>
              <img src={SIG} className='contactMedia'></img>
              <p className='text nr'>Msg: +355 68 20 07 150</p>
            </div>
          </div>
          <div>
            <p className='textVend'>{t('Në Peshkopi:')}</p>
            <div className='contact'>
              <img src={PHONE} className='contactMedia'></img>
              <p className='text nr'>Tel: +355 218 2 20 13</p>
            </div>
            <div className='contact'>
              <img src={WP} className='contactMedia'></img>
              <p className='text nr'>Msg: +355 69 20 95 802</p>
            </div>
            <div className='contact'>
              <img src={SIG} className='contactMedia'></img>
              <p className='text nr'>Msg: +355 68 20 07 150</p>
            </div>
          </div>
          <div>
            <p className='textVend'>Email: </p>
            <div className='contact'>
              <img src={YAHOO} className='contactMedia'></img>
              <p className='text nr'>albkredit.shpk@yahoo.com</p>
            </div>
            <p className='text hide'>|</p>
            <p className='text hide'>|</p>
          </div>
        </div>

      </div>
    </>
  )
}

export default About