import React, { useEffect, useState } from 'react';
import { getDocs, collection, deleteDoc, doc, orderBy, query } from "firebase/firestore";
import { auth, db } from "../firebase-config";
import { getDatabase, ref, orderByChild } from "firebase/database";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../index.css";
import "../App.css";
import '../styles/News.css'

function Home({ isAuth }) {
  const [postLists, setPostList] = useState([]);
  const postsCollectionRef = collection(db, "news");

  const deletePost = async (id) => {
    const postDoc = doc(db, "news", id);
    await deleteDoc(postDoc)
    const data = await getDocs(postsCollectionRef);
    setPostList((data.docs || []).map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    const getPosts = async () => {

      const q = query(postsCollectionRef, orderBy("timestamp", "desc"));

      const data = await getDocs(q);

      setPostList((data.docs || []).map((doc) => ({ ...doc.data(), id: doc.id })));

    };

    getPosts();
  }, []);
  window.onscroll = () => {
    toggleTopButton();
  }
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function toggleTopButton() {
    if (document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20) {
      document.getElementById('back-to-up').classList.remove('d-none');
    } else {
      document.getElementById('back-to-up').classList.add('d-none');
    }
  }
  return (
    <div>

      <button className="toTop btn btn-sm btn-primary 
      position-fixed bottom-0 end-0
      translate-middle d-none" onClick={scrollToTop} id="back-to-up">
        &#8648;
      </button>

      <div className='' style={{ 'marginTop': '150px' }}>
        {postLists.map((post, i) => {
          return (
            <div key={`key-${i}`} className=''>
              {/* <div className="container">
                <div className='row newsRow'>

                  <div className='col-md-3 col-sm-4 col-xs-4'>
                    <img className="newsImg" src={post.imazh} />
                  </div>

                  <div className='col-md-9 col-sm-8 col-xs-8'>
                    <h1 className="newsFrom" > {post.from} </h1>
                     <div className='deletePost'>
                      {isAuth && post.author.id === auth.currentUser.uid && (
                        <button
                          onClick={() => {
                            deletePost(post.id);
                          }}
                        > &#128465;
                        </button>
                      )}
                    </div> 
                    <h2 className="newsTitle">{post.title}</h2>
                  </div>

                </div>

              </div> */}
              {/* <h2 className="ageNew"><i>Author - </i>{post.author.name}</h2> */}

              <div className="projcard-container">

                <a href={post.link} target="_blank">
                  <div className="projcard projcard-blue">
                    <div className="projcard-innerbox">
                      <img className="projcard-img" src={post.imazh} alt="" />
                      <div className="projcard-textbox">
                        <div className="projcard-title">{post.from}</div>
                        <div className="projcard-subtitle">{post.title}</div>

                      </div>
                    </div>
                  </div>
                </a>

              </div>

            </div>
          );
        })}
      </div>
    </div >
  )
}

export default Home;