export default {
  "Kreu": "Home",
  "Konverto": "Convert",
  "Lajme": "News",
  "Rreth nesh": "About us",
  "Vendos Kurset": "Vendos Kurset",
  "Vendos Lajmet": "Vendos Lajmet",
  "BLEJMË": "WE BUY",
  "SHESIM": "WE SELL",
  "VALUTA": "CURRENCY",
  "...që nga 1993": "...since 1993",
  "KLIENTI BLEN": "CLIENT BUYS",
  "KLIENTI SHET": "CLIENT SELLS",
  "Përditësuar": "Updated",
  "ora": "time",
  "Nga": "From",
  "Në": "To",
  "Shuma:": "Sum:",
  "Rezultati:": "Result:",
  "Pastro": "Clear",
  "Kush jemi ne?": "Who are we?",
  "Ku mund të na gjeni?": "Where can you find us?",
  "Në Tiranë:": "In Tirana:",
  "Në Peshkopi:": "In Peshkopia:",
  "Rruga: “Ded Gjon Luli”, 1/14, Tiranë, Shqipëri": "Street “Ded Gjon Luli”, 1/14, Tirana, Albania",
  "Bulevardi “Nazmi Rushiti”, Peshkopi, Shqipëri": "Boulevard “Nazmi Rushiti”, Peshkopi, Albania",
  "E Hënë - E Shtunë": "Monday to Saturday",
  "E Dielë": "Sunday",
  "Kontakt": "Contact",
  "Mbyll": "Close",
  "është krenare të jetë e para shoqëri shqiptare për Këmbime Valutore prej vitit 1993. Ne jemi të specalizuar në Këmbime Valutore dhe në Transferta  Parash. Ne ofrojmë kurset e këmbimit më të mira per individet dhe bizneset dhe nuk aplikojmë komisione shtesë për këmbimin, si në rast kur këmbimi bëhet me para në dorë, por as në rastin kur këmbimi bëhet nëpërmjet llogarive bankare. Zyrat tona gjenden në qendrën e Tiranës dhe  të qytetit të Peshkopisë.": "is proud to be the first Albanian Currency Exchange Bureau. Since 1993, ALB-KREDIT has established an outstanding reputation for offering the most competitive exchange rates, money transfers and excellent customer service to a long standing and ever-expanding clientele.  We offer the best exchange rates to our individual or business clients with no commissions. Our offices are located in the center of Tirana and the city of Peshkopia."
}