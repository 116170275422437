// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  /* apiKey: "AIzaSyCEkrKxg6jFEiPHl3JdygUQxattbm4rYZw",
  authDomain: "alb-kredit.firebaseapp.com",
  projectId: "alb-kredit",
  storageBucket: "alb-kredit.appspot.com",
  messagingSenderId: "37557285609",
  appId: "1:37557285609:web:957a3344bc788ddc7a3678" */
  apiKey: "AIzaSyAchWZtzMFNETywfPZ9cFHn3CR53u6Rm1o",
  authDomain: "albkredit-4c1fd.firebaseapp.com",
  projectId: "albkredit-4c1fd",
  storageBucket: "albkredit-4c1fd.appspot.com",
  messagingSenderId: "1095060049841",
  appId: "1:1095060049841:web:c593ce7a1fdfcc8eb23790"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();





