export default {
  "Kreu": "Kreu",
  "Konverto": "Konverto",
  "Lajme": "Lajme",
  "Rreth nesh": "Rreth nesh",
  "Vendos Kurset": "Vendos Kurset",
  "Vendos Lajmet": "Vendos Lajmet",
  "BLEJMË": "BLEJMË",
  "SHESIM": "SHESIM",
  "VALUTA": "VALUTA",
  "...që nga 1993": "...që nga 1993",
  "KLIENTI BLEN": "KLIENTI BLEN",
  "KLIENTI SHET": "KLIENTI SHET",
  "Përditësuar": "Përditësuar",
  "ora": "ora",
  "Nga": "Nga",
  "Në": "Në",
  "Shuma:": "Shuma:",
  "Rezultati:": "Rezultati:",
  "Pastro": "Pastro",
  "Kush jemi ne?": "Kush jemi ne?",
  "Ku mund të na gjeni?": "Ku mund të na gjeni?",
  "Në Tiranë:": "Në Tiranë:",
  "Në Peshkopi:": "Në Peshkopi",
  "Rruga: “Ded Gjon Luli”, 1/14, Tiranë, Shqipëri": "Rruga: “Ded Gjon Luli”, 1/14, Tiranë, Shqipëri",
  "Bulevardi “Nazmi Rushiti”, Peshkopi, Shqipëri": "Bulevardi “Nazmi Rushiti”, Peshkopi, Shqipëri",
  "E Hënë - E Shtunë": "E Hënë - E Shtunë",
  "E Dielë": "E Dielë",
  "Kontakt": "Kontakt",
  "Mbyll": "Mbyll",
  "është krenare të jetë e para shoqëri shqiptare për Këmbime Valutore prej vitit 1993. Ne jemi të specalizuar në Këmbime Valutore dhe në Transferta  Parash. Ne ofrojmë kurset e këmbimit më të mira per individet dhe bizneset dhe nuk aplikojmë komisione shtesë për këmbimin, si në rast kur këmbimi bëhet me para në dorë, por as në rastin kur këmbimi bëhet nëpërmjet llogarive bankare. Zyrat tona gjenden në qendrën e Tiranës dhe  të qytetit të Peshkopisë.": "është krenare të jetë e para shoqëri shqiptare për Këmbime Valutore prej vitit 1993. Ne jemi të specalizuar në Këmbime Valutore dhe në Transferta  Parash. Ne ofrojmë kurset e këmbimit më të mira per individet dhe bizneset dhe nuk aplikojmë komisione shtesë për këmbimin, si në rast kur këmbimi bëhet me para në dorë, por as në rastin kur këmbimi bëhet nëpërmjet llogarive bankare. Zyrat tona gjenden në qendrën e Tiranës dhe  të qytetit të Peshkopisë."
} 
